<template>
  <div class="case-study-slider">
    <T3HtmlParser
      v-if="sliderHeading"
      tag="h2"
      class="case-study-slider__heading heading-3 content-container-template"
      :content="sliderHeading"
    />

    <ClientOnly>
      <div
        v-if="clientList.length"
        class="case-study-slider__wrapper content-container-template"
      >
        <button
          ref="next"
          class="swiper-button swiper-button-next"
        />

        <UiSwiper
          :options="swiperOptions"
          class="case-study-slider__carousel"
        >
          <SwiperSlide
            v-for="client in clientList"
            :key="client.id"
            v-slot="{ isActive }"
            class="content-container-template"
          >
            <div class="case-study-slider__item">
              <div class="case-study-slider__content-wrapper">
                <UiImg
                  :image="client.logo"
                  class="case-study-slider__logo"
                  use-image-size
                />

                <T3HtmlParser
                  v-if="client.bodytext"
                  class="case-study-slider__desc"
                  :content="client.bodytext"
                />

                <ButtonBase
                  class="case-study-slider__button"
                  :button="customButtonProps(client)"
                  :tabindex="isActive ? 0 : -1"
                />
              </div>

              <div
                v-if="client.image"
                class="case-study-slider__image-wrapper"
              >
                <UiImg
                  :image="client.image"
                  :sizes="{ xs: '400', sm: '800' }"
                  class="case-study-slider__image"
                  use-image-size
                />
              </div>
            </div>
          </SwiperSlide>
        </UiSwiper>

        <button
          ref="prev"
          class="swiper-button swiper-button-prev"
        />

        <div
          ref="pagination"
          class="swiper-pagination"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { Navigation, Pagination } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed, ref } from 'vue'

import { ButtonBase, UiImg, UiSwiper } from '~ui/components'
import type { UiCeCategoryBoxesItemProps, UiCeCategoryBoxesProps } from '~ui/types'

const props = defineProps<UiCeCategoryBoxesProps>()
const sliderSize = 6

const sliderHeading = computed(() => props.pageData?.badge?.header || '')

const buttonContent = {
  en: 'Read Case Study',
  pl: 'Dowiedz Się Więcej',
  de: 'Jetzt Case Study Lesen'
}

const clientList = computed(() => {
  const clientArray = [...props.caseStudyList]

  if (!clientArray.length) return []

  if (!props.pageTagList.length) {
    const randomClientArray = []

    for (let i = 0; i < sliderSize; i++) {
      const randomIndex = Math.floor(Math.random() * clientArray.length)
      randomClientArray.push(...clientArray.splice(randomIndex, 1))
    }

    return randomClientArray
  }

  return clientArray.filter(({ categories }) => {
    const clientCategoryArray = categories.map(({ category }) => category?.name)
    return props.pageTagList.some((element) =>
      clientCategoryArray.includes(element)
    )
  })
})

const customButtonProps = (item: UiCeCategoryBoxesItemProps) => ({
  content: buttonContent[props.lang as keyof typeof buttonContent],
  variant: 'outline-white',
  link: item.url
})

const pagination = ref(null)
const next = ref(null)
const prev = ref(null)

const swiperOptions = computed(
  (): SwiperOptions => ({
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    pagination: {
      el: pagination.value,
      type: 'bullets',
      clickable: true,
      bulletElement: 'button'
    },
    navigation: {
      nextEl: next.value,
      prevEl: prev.value
    }
  })
)
</script>

<style lang="scss">
.case-study-slider {
  background-image: url('~ui/assets/slider/background-#{$prefix}.png');
  background-repeat: repeat;
  padding-top: 3rem;
  margin-bottom: 5rem;
  position: relative;

  &__heading {
    margin-bottom: 2rem;
    text-align: center;
  }

  @include media-query(md) {
    &__wrapper {
      height: 28rem;
      translate: 0 1rem;
    }

    &__carousel {
      height: 100%;
      width: 100%;
    }
  }

  & .swiper-button {
    top: 20vh;
    position: absolute;
    height: rem(48px);
    width: rem(48px);
    background-color: hsl(0 0% 100% / 0.15);
    border-radius: em(2px);
    transition: background-color 0.3s ease-in-out;

    --arrow-spacing: 34px;

    @include media-query(md) {
      display: none;
    }

    @include media-query(lg) {
      display: block;
      top: 34%;

      --arrow-spacing: -96px;
    }

    &::after {
      filter: invert(1);
    }

    &-prev {
      left: var(--arrow-spacing);
    }

    &-next {
      right: var(--arrow-spacing);
    }

    &:not(&-disabled):hover {
      background-color: hsl(0 0% 100% / 0.25);
    }
  }

  & .swiper-pagination {
    @include media-query(md) {
      justify-content: flex-start;
    }

    &-bullet-active {
      &:hover::before,
      &::before {
        background-color: var(--color__background-color, color(primary));
      }
    }
  }

  & .swiper-slide {
    height: unset;
  }

  &__item {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: relative;
    height: 100%;
    width: 100%;
    padding: rem(16px) rem(1px);

    @include media-query(md) {
      flex-direction: row;
      width: calc(100% + rem(32px));
      padding: 0 em(16px);
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: rem(19.25px);
    padding-block: rem(22.5px);

    @include media-query(md) {
      max-width: 40%;
    }
  }

  &__desc {
    font-size: clamp(rem(16px), 5.5vw, rem(34px));
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: rem(16px);

    & * {
      margin: 0;
    }
  }

  &__button {
    background-color: transparent;
    margin-inline: 0;

    & > span {
      background-color: transparent;
    }

    &:is(:hover, :focus-visible) > span {
      background-color: rgba(color(black), 4%);
    }

    &:active > span {
      background-color: rgba(color(black), 8%);
    }
  }

  &__image {
    &-wrapper {
      height: rem(300px);
      width: 100%;

      @include media-query(md) {
        position: absolute;
        height: 100%;
        max-width: 60%;
        right: rem(32px);
        top: 5%;
      }
    }

    height: 100%;
    width: 100%;
    object-fit: contain;

    @include media-query(max-width sm) {
      width: 95%;
      display: block;
      margin-inline: auto;
    }
  }
}
</style>
